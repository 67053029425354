import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Icons from "../components/icons";
import "./../styles/cultrpassnew.css";
import "./../styles/landing.css";
import HomeSVG from "../images/2.0/home.svg";
import WhiteCrystal from "../images/2.0/white-crystal.png";
import TopazCrystal from "../images/2.0/topaz-crystal.png";
import SapphireCrystal from "../images/2.0/sapphire-crystal.png";
import EmeraldCrystal from "../images/2.0/emerald-crystal.png";
import RubyCrystal from "../images/2.0/ruby-crystal.png";
import OpalCrystal from "../images/2.0/opal-crystal.png";
import Footer from "../components/footer";

const TheOneStar = () => {
  return (
    <span class="utility-bullet">
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 0L8.5716 4.83688H13.6574L9.5429 7.82624L11.1145 12.6631L7 9.67376L2.8855 12.6631L4.4571 7.82624L0.342604 4.83688H5.4284L7 0Z"
          fill="inherit"
        />
      </svg>
    </span>
  );
};

const OpalStar = () => {
  return (
    <span class="utility-bullet">
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 0L8.5716 4.83688H13.6574L9.5429 7.82624L11.1145 12.6631L7 9.67376L2.8855 12.6631L4.4571 7.82624L0.342604 4.83688H5.4284L7 0Z"
          fill="inherit"
        />
      </svg>
    </span>
  );
};

const CultrPassDrop002 = () => {
  const [footerVisible, setFooterVisible] = React.useState(false);
  useEffect(() => {
    window !== "undefined" && window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DROP 002 UTILITIES - CULT&amp;RAIN™</title>
        <link
          rel="canonical"
          href="https://www.cultandrain.com/cultr-pass-drop002"
        />
        <meta
          name="description"
          content="The first luxury fashion brand born from the crypto universe merging NFTs with exclusive, physical redeemables. Join our Web3 revolution."
        />
      </Helmet>
      <div className="redeemables redeemables-page" style={{ padding: "0" }}>
        <div>
          <a href="/" className="homebtn">
            <img src={HomeSVG} />
          </a>
          <Icons mintVisible={true} page="genesis" />
        </div>
        <div class="wrapper">
          <div class="redeemables-header">
            <h1 style={{color:'white'}}>
              DROP 002<br />
              UTILITIES
            </h1>
            <h2>“CULTR PASS”</h2>
            <p>VIRGO Hoodie</p>
            <h3>
                209 NFT’s<br />INTRODUCING NFT HOLDER REWARDS
              <br />
              <small>(ALL RARITIES ELIGIBLE)</small>
            </h3>
          </div>
          <div className="redeemables-body no-middle redeemables-smiles">
            <div
              className="redeemable-column redeemable-column-alone"
              style={{ width: "100%" }}
            >
              <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
                <span className="opal">THE ONE #1/1</span>
              </p>
              <div style={{textAlign:'center'}}>
                <img
                  src={OpalCrystal}
                  alt="Opal Crystal"
                  className="crystal"
                  width="25"
                  height="70"
                />
              </div>
              <ul className="opal" style={{textAlign:'center',marginTop:'20px'}}>
                <li>&bull; Free Mint Drop 004</li>
                <li>&bull; Free Mint Drop 003</li>
                <li>&bull; Limited Edition Custom Sneaker</li>
                <li>&bull; Free Mint CULTR Pad</li>
                <li>&bull; Matching Luxury Hoodie with NFC Chip (AI Print Version)</li>
                <li>&bull; Free Mint CULTR Avatar</li>
                <li>&bull; Free AR Wearable NFT by Dress X</li>
                <li>&bull; Air Drop Rewards</li>
                <li>&bull; 50% Future E-com Discount (Limited to 1 drop per year)</li>
                <li>&bull; 50% Future Pop-Up Discount (Limited to 1 drop per year)</li>
                <li>&bull; CULTR Giveaway Access</li>
                <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
                <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
                <li>&bull; Exclusive Access to CULT&RAIN Inner Circle Events</li>
                <li>&bull; Private Discord Channel</li>
                <li>&bull; Rarity Colored Discord Title</li>
              </ul>
            </div>
          </div>
          <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
            <div
              className="redeemable-column redeemable-column-alone"
              style={{ width: "100%" }}
            >
              <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
                <span className="opal">OPAL #001/52</span>
              </p>
              <div style={{textAlign:'center'}}>
                <img
                  src={OpalCrystal}
                  alt="Opal Crystal"
                  className="crystal"
                  width="25"
                  height="70"
                />
              </div>
              <ul className="opal" style={{textAlign:'center',marginTop:'20px'}}>
                <li>&bull; Free Mint Drop 003</li>
                <li>&bull; Limited Edition Custom Sneaker</li>
                <li>&bull; Free Mint CULTR Pad</li>
                <li>&bull; Matching Luxury Hoodie with NFC Chip (AI Print Version)</li>
                <li>&bull; Free Mint CULTR Avatar</li>
                <li>&bull; Free AR Wearable NFT by Dress X</li>
                <li>&bull; Air Drop Rewards</li>
                <li>&bull; 25% Future E-com Discount (Limited to 1 drop per year)</li>
                <li>&bull; 25% Future Pop-Up Discount (Limited to 1 drop per year)</li>
                <li>&bull; CULTR Giveaway Access</li>
                <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
                <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
                <li>&bull; Exclusive Access to CULT&RAIN Inner Circle Events</li>
                <li>&bull; Private Discord Channel</li>
                <li>&bull; Rarity Colored Discord Title</li>
              </ul>
            </div>
          </div>
          <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
            <div
              className="redeemable-column redeemable-column-alone"
              style={{ width: "100%" }}
            >
              <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
                <span className="ruby">RUBY #002-005/52</span>
              </p>
              <div style={{textAlign:'center'}}>
                <img
                  src={RubyCrystal}
                  alt="Ruby Crystal"
                  className="crystal"
                  width="25"
                  height="70"
                />
              </div>
              <ul className="ruby" style={{textAlign:'center',marginTop:'20px'}}>
                <li>&bull; Limited Edition Custom Sneaker</li>
                <li>&bull; Free Mint CULTR Pad</li>
                <li>&bull; Matching Luxury Hoodie with NFC Chip (AI Print Version)</li>
                <li>&bull; Free Mint CULTR Avatar</li>
                <li>&bull; Free AR Wearable NFT by Dress X</li>
                <li>&bull; Air Drop Rewards</li>
                <li>&bull; 10% Future E-com Discount (Limited to 1 drop per year)</li>
                <li>&bull; 10% Future Pop-Up Discount (Limited to 1 drop per year)</li>
                <li>&bull; CULTR Giveaway Access</li>
                <li>&bull; Pre-Mint Access</li>
                <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
                <li>&bull; VIP Access to CULT&RAIN Events</li>
                <li>&bull; Private Discord Channel</li>
                <li>&bull; Rarity Colored Discord Title</li>
              </ul>
            </div>
          </div>
          <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
            <div
              className="redeemable-column redeemable-column-alone"
              style={{ width: "100%" }}
            >
              <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
                <span className="emerald">EMERALD #006-035/52</span>
              </p>
              <div style={{textAlign:'center'}}>
                <img
                  src={EmeraldCrystal}
                  alt="Emerald Crystal"
                  className="crystal"
                  width="25"
                  height="70"
                />
              </div>
              <ul className="emerald" style={{textAlign:'center',marginTop:'20px'}}>
                <li>&bull; Free Mint CULTR Pad</li>
                <li>&bull; Matching Luxury Hoodie with NFC Chip (AI Print Version)</li>
                <li>&bull; Free Mint CULTR Avatar</li>
                <li>&bull; Free AR Wearable NFT by Dress X</li>
                <li>&bull; Air Drop Rewards</li>
                <li>&bull; 10% Future E-com Discount (Limited to 1 drop per year)</li>
                <li>&bull; 10% Future Pop-Up Discount (Limited to 1 drop per year)</li>
                <li>&bull; CULTR Giveaway Access</li>
                <li>&bull; Pre-Mint Access</li>
                <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
                <li>&bull; VIP Access to CULT&RAIN Events</li>
                <li>&bull; Private Discord Channel</li>
                <li>&bull; Rarity Colored Discord Title</li>
              </ul>
            </div>
          </div>
          <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
            <div
              className="redeemable-column redeemable-column-alone"
              style={{ width: "100%" }}
            >
              <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
                <span className="sapphire">SAPPHIRE #036-52/52</span>
              </p>
              <div style={{textAlign:'center'}}>
                <img
                  src={SapphireCrystal}
                  alt="Sapphire Crystal"
                  className="crystal"
                  width="25"
                  height="70"
                />
              </div>
              <ul className="sapphire" style={{textAlign:'center',marginTop:'20px'}}>
                <li>&bull; Matching Luxury Hoodie with NFC Chip (Logo Print Version)</li>
                <li>&bull; Free Mint CULTR Avatar</li>
                <li>&bull; Free AR Wearable NFT by Dress X</li>
                <li>&bull; Air Drop Rewards</li>
                <li>&bull; 10% Future E-com Discount (Limited to 1 drop per year)</li>
                <li>&bull; 10% Future Pop-Up Discount (Limited to 1 drop per year)</li>
                <li>&bull; CULTR Giveaway Access</li>
                <li>&bull; Pre-Mint Access</li>
                <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
                <li>&bull; VIP Access to CULT&RAIN Events</li>
                <li>&bull; Private Discord Channel</li>
                <li>&bull; Rarity Colored Discord Title</li>
              </ul>
            </div>
          </div>
          <div className="redeemables-spacer"></div>
          <div className="redeemables-spacer"></div>
          <div className="redeemables-body no-middle redeemables-smiles">
            <div
              className="redeemable-column redeemable-column-alone"
              style={{ width: "100%" }}
            >
              <p className="redeemables-subtitle redeemables-subtitle-whale">
                <span className="redeemables-subtitle-blue">CULTR COLLECTOR</span>
                <br />
                WHALE REWARDS
                <br />
                <small>
                Added bonus utility for our ULTIMATE 10+ NFT Collectors
                </small>
              </p>
              <h5
                style={{
                  textAlign: "center",
                  fontSize: "1.5em",
                  fontFamily: "Avenir Black",
                  marginBottom: "0em",
                }}
              >
                HOLD 10 002 DROP NFTs or MORE
              </h5>
              <ul style={{marginTop:'20px',textAlign:'center'}}>
                <li>&bull; 50% off eCommerce shop (1 per year)</li>
                <li>&bull; CULTR Pad Custom Whale Skin</li>
                <li>&bull; Pre-Mint Access to Future Drops with 20% Discount</li>
                <li>&bull; 30% Future E-com Discount (Limited to 1 drop per year)</li>
                <li>&bull; 30% Future Pop-Up Discount (Limited to 1 drop per year)</li>
                <li>&bull; Private Discord Channel</li>
                <li>&bull; Exclusive Colored Discord Title</li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
      <Footer setFooterVisible={setFooterVisible} />
    </main>
  );
};

export default CultrPassDrop002;